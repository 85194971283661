/* CLOUDS */
.cloud-separator {
  top: -8.75vh;
  left: 0;
  fill: #FFFFFF;
  z-index: 1;
  position: absolute;
  width: 100vw;
  height: 9vh;
}

/* PAINT */
.paint-separator {
  background: #000;
  fill: #980037;
}

/* TRIANGLE */
#bigTriangleShadow {
  background: #333333;
  margin-bottom: -0.4em;
  width: 100%;
  height: 10vh;
}
#trianglePath1 {
  fill: #DDDDDD;
}
#trianglePath2 {
  fill: #444444;
  stroke: #444444;
}

@media only screen and (min-width: 2300px) and (max-width: 3000px) {
  /* CLOUDS */
  .cloud-separator {
    top: -6.5vh;
    height: 6.75vh;
  }
}

@media only screen and (min-width: 1935px) and (max-width: 2300px) {
  /* CLOUDS */
  .cloud-separator {
    top: -5.25vh;
    height: 5.5vh;
  }
}

@media only screen and (min-width: 1640px) and (max-width: 1935px) {
  /* CLOUDS */
  .cloud-separator {
    top: -4.75vh;
    height: 5vh;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1639px) {
  /* CLOUDS */
  .cloud-separator {
    top: -4.5vh;
    height: 4.75vh;
  }
}

@media only screen and (min-width: 744px) and (max-width: 1365px) {
  /* CLOUDS */
  .cloud-separator {
    top: -9.75vh;
    height: 10vh;
    width: 225vw;
  }
}

@media only screen and (min-width: 0px) and (max-width: 743px) {
  /* CLOUDS */
  .cloud-separator {
    width: 300vw;
  }

  /* TRIANGLE */
  #bigTriangleShadow {
    height: 2em;
  }
}